import React from 'react'
import { graphql } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'The Montage at Palmetto Bluff — Bluffton, South Carolina' },
    { img: data.i2, caption: 'The Montage at Palmetto Bluff — Bluffton, South Carolina' },
    { img: data.i3, caption: 'The Ritz Carlton Lodge - Lake Oconee— Greensboro, Georgia' },
    { img: data.i4, caption: 'The Ritz Carlton Lodge - Lake Oconee — Greensboro, Georgia' },
    { img: data.i5, caption: 'ALOFT — Ashburn, Virginia' },
    { img: data.i6, caption: 'ALOFT — Ashburn, Virginia' },
    { img: data.i7, caption: 'Moxy Hotel — Denver, Colorado' },
    { img: data.i8, caption: 'Moxy Hotel — Denver, Colorado' },
    { img: data.i9, caption: 'Hilton Garden Inn — Alexandria, Virginia' },
    { img: data.i10, caption: 'Hilton Garden Inn — Alexandria, Virginia' },
    { img: data.i11, caption: 'Hilton Garden Inn — Suffolk, Virginia' },
    { img: data.i12, caption: 'Hampton Inn & Suites — Columbus, Ohio' },
    { img: data.i13, caption: 'Hyatt Place — Ridgeland, Mississippi' },
    { img: data.i14, caption: 'Fairfield Inn — Germantown, Maryland' },
  ];

  return (
    <Layout>
      <SEO title="Gallery: Hotels" keywords={[]}/>
      <div className="container gallery-container">
        <h2 className='subtitle subtitle--xl color-primary'>Gallery</h2>
        <h1 className='title title--xxxl color-primary'>Hotels</h1>
        <div className='carousel-container carousel-container--lg'>
          <Carousel
            content={images}
            carWidth={846}
            carHeight={476}
          />
        </div>
        <div>
          <p>The Infinity Structural System is the best structural option for Hotels up
          to 10-Stories. Hotels are typically modular and repetitious which creates a
          very economical application for the Infinity System.  The speed of construction
          ensures the owner the earliest possible opening date, which reduces construction
          loan interest as well as the cost of the G/C’s General Conditions.  And most
          importantly, this also enables the Owner to begin generating room revenues
          earlier. The end result is a high quality, noise-resistant Hotel building that will
          maintain its value and protect the Owner’s investment for many years to come.</p>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
         query {
           i1: file(relativePath: { eq: "gallery/hotels/gallery/01-MonPB010_(42917).png" }) {
             ...GalleryCarouselImageFragment
           }
           i2: file(relativePath: { eq: "gallery/hotels/gallery/02-Montage-Finished2_(NEW).JPG" }) {
             ...GalleryCarouselImageFragment
           }
           i3: file(relativePath: { eq: "gallery/hotels/gallery/03-RitzL010_(33922).png" }) {
             ...GalleryCarouselImageFragment
           }
           i4: file(relativePath: { eq: "gallery/hotels/gallery/04-RitzL030_(34240).png" }) {
             ...GalleryCarouselImageFragment
           }
           i5: file(relativePath: { eq: "gallery/hotels/gallery/05-ALOFT040_(41275).png" }) {
             ...GalleryCarouselImageFragment
           }
           i6: file(relativePath: { eq: "gallery/hotels/gallery/06-ALOFT140_(41958).png" }) {
             ...GalleryCarouselImageFragment
           }
           i7: file(relativePath: { eq: "gallery/hotels/gallery/07-MoxyH010_(43305).png" }) {
             ...GalleryCarouselImageFragment
           }
           i8: file(relativePath: { eq: "gallery/hotels/gallery/08-MoxyH040_(43308).png" }) {
             ...GalleryCarouselImageFragment
           }
           i9: file(relativePath: { eq: "gallery/hotels/gallery/09-HGIAx010_(42883).png" }) {
             ...GalleryCarouselImageFragment
           }
           i10: file(relativePath: { eq: "gallery/hotels/gallery/10-HGIAx030_(42885).png" }) {
             ...GalleryCarouselImageFragment
           }
           i11: file(relativePath: { eq: "gallery/hotels/gallery/11-HGIVA020_(40645).png" }) {
             ...GalleryCarouselImageFragment
           }
           i12: file(relativePath: { eq: "gallery/hotels/gallery/12-HICOH010_(41328).png" }) {
             ...GalleryCarouselImageFragment
           }
           i13: file(relativePath: { eq: "gallery/hotels/gallery/13-HyaMS010_(41992).png" }) {
             ...GalleryCarouselImageFragment
           }
           i14: file(relativePath: { eq: "gallery/hotels/gallery/14-FIGMD010_(41295).png" }) {
             ...GalleryCarouselImageFragment
           }
         }
       `
